<template>
  <div class="support">
    <div class="container">
      <div class="banner">
        <div class="auto-container">
          <h1>支持</h1>
          <p>
            <a href="/">首页</a>
            <Icon
              class="arrow"
              type="ios-arrow-forward"
            />支持
          </p>
        </div>
      </div>
      <div class="auto-container">
        <div class="info">
          <Tabs
            :value="menuId"
            @on-click="setmenuId"
          >
            <TabPane
              :label="item.title"
              v-for="(item, index) in navList"
              :key="index"
              :name="`${item.serId}`"
            >

            </TabPane>
          </Tabs>
          <section
            class="featured-section"
          >
            <Row>
              <Col
                :xl="8"
                :lg="8"
                :md="24"
                :sm="24"
                :xs="24"
                v-for="(item, index) in contlist"
                :key="index"
              >
              <div class="feature-block-two">
                <div class="inner-box">
                  <div
                    class="icon-box"
                    @click="goInfo(item)"
                  >
                    <span class="icon"><img
                        src="../../assets/icons/13.png"
                        alt=""
                      /></span>
                  </div>
                  <h3>
                    <a href="#">{{ item.title }}</a>
                  </h3>
                </div>
              </div>
              </Col>
            </Row>
          </section>
          <!-- 相关下载 -->
          <!-- <Row
            :gutter="30"
            class="down"
            v-if="menuId == 54"
          >
            <Col
              :xl="12"
              :lg="12"
              :md="24"
              :sm="24"
              :xs="24"
              v-for="(item,index) in contlist"
              :key="index"
            ><a :href="item.downLink">
              <div class="inner-box">
                <div class="content">
                  <div class="icon-box">
                    <img
                      :src="item.wechatPic"
                      alt=""
                    />
                  </div>
                  <div>
                    <h3>{{item.title}}</h3>
                    <div class="time">更新时间：{{item.createTime}}</div>
                    <div class="text">{{item.abst}}</div>
                  </div>
                </div>

              </div>
            </a>
            </Col>
          </Row> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import support from "./index.js";
export default support;
</script>

<style lang="less" scoped>
@import "./index.less";
</style>
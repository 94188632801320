import axios from "axios"
export default {
    data() {
        return {
            weburl: "https://www.rayscloud.cn/webapi",
            siteId:localStorage.getItem("siteId"),
            menuId:localStorage.getItem("menuId"),
            navList: [],
            contlist: [],
            childId: 0,
        }
    },
    methods: {
        getchild() {
            axios.get(`${this.weburl}/api/Site/Menu/list/${this.siteId}/${this.menuId}`)
                .then((res) => {
                    if (res.status == 200) {
                        this.navList = res.data.data;
                        if (this.navList.length > 0) {
                            this.childId = this.navList[0].serId;
                            this.getchild2();
                        }
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        setmenuId(name) {
            this.childId = name;
            localStorage.setItem("childId",this.childId);
            this.getchild2();            
        },
        getchild2() {
            axios.get(`${this.weburl}/api/Site/Menu/${this.siteId}/${this.childId}`)
                .then((res) => {
                    if (res.status == 200) {
                        localStorage.setItem("childId",this.childId);
                        this.contlist = res.data.data;
                    }
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        goInfo(item) {
            localStorage.setItem("secondMenu",JSON.stringify(item));
            this.$router.push(item.link);
        },
    },
    created() {
        this.getchild();
    },
}